import LazyLoad from 'vanilla-lazyload'
import $ from 'jquery'
import AOS from 'aos'
import Swiper from 'swiper/bundle'

require('magnific-popup')

// Place any code in here.
$(function () {
  // eslint-disable-next-line no-new
  new LazyLoad({
    elements_selector: '.lazyload'
  })

  /** navbar reference **/
  const $navbar = $('.main-nav')
  const stickyPoint = 90

  const navbarSticky = function () {
    if ($(window).scrollTop() >= stickyPoint) {
      $navbar.addClass('navbar-sticky')
    } else {
      $navbar.removeClass('navbar-sticky')
    }
  }

  /**
   * STICKY MENU
   **/
  $(window).on('scroll', navbarSticky)

  navbarSticky()

  /**
   *  NAVBAR SIDE COLLAPSIBLE - On Mobiles
   **/
  $('.navbar-toggler', $navbar).on('click', function () {
    if (!$navbar.is('.st-nav')) $navbar.toggleClass('navbar-expanded')
  })

  /** PLUGINS INITIALIZATION */
  /* Bellow this, you can remove the plugins you're not going to use.
   * If you do so, remember to remove the script reference within the HTML.
   **/

  /**
   * AOS
   * Cool scrolling animations
   **/
  AOS.init({
    offset: 100,
    duration: 1500,
    disable: 'mobile'
  });

  /**
   * Pricing Tables
   */
  (function () {
    $('.pricing-table-basis').on('change', 'input[name="pricing-value"]', function () {
      const $container = $(this).closest('.pricing-container')
      const period = this.value

      $('.odometer').each(function () {
        this.innerHTML = $(this).data(period + '-price')
      })

      if (period === 'yearly') {
        $container.find('.yearly-pricing.d-none').removeClass('d-none')
      } else {
        $container.find('.yearly-pricing').addClass('d-none')
      }
    })
  })();

  /**
   * POPUPS
   **/
  (function () {
    $('.modal-popup').each(function () {
      const $element = $(this)

      // Some default to apply for all instances of Modal
      const defaults = {
        removalDelay: 500,
        preloader: false,
        midClick: true,
        callbacks: {
          beforeOpen: function () {
            this.st.mainClass = this.st.el.attr('data-effect')
          }
        }
      }

      // Defaults to use for specific types
      const typeDefaults = {
        image: {
          closeOnContentClick: true
        },
        gallery: {
          delegate: 'a',
          // when gallery is used change the type to 'image'
          type: 'image',
          tLoading: 'Loading image #%curr%...',
          mainClass: 'mfp-with-zoom mfp-img-mobile',
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
          },
          image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
          }
        }
      }

      // Load configuration values from data attributes
      const type = $element.data('type') || 'inline'
      const zoomSpeed = $element.data('zoom') || false
      const focus = $element.data('focus') || false

      const attributes = {}

      if (zoomSpeed) {
        attributes.zoom = {
          enabled: true,
          duration: zoomSpeed
        }
      }

      if (focus) {
        attributes.focus = focus
      }

      // According to the type, get the JSON configuration for each
      $.each(['image', 'gallery'], function () {
        const attr = $element.data(this) || false

        if (attr) {
          typeDefaults[type][this] = attr
        }

        // remove the values from the markup
        $element.removeAttr('data-' + this)
      })

      const options = $.extend({}, defaults, {
        type
      }, typeDefaults[type], attributes)

      $element.magnificPopup(options)
    })

    $(document).on('click', '.modal-popup-dismiss', function (e) {
      e.preventDefault()
      $.magnificPopup.close()
    })
  })();

  (function () {
    /**
     * Swiper Initialization
     **/
    $('.swiper-container').each(function () {
      const $this = $(this)
      const boolData = {
        breakpoints: $this.data('sw-breakpoints'),
        active_selector: $this.data('sw-active-selector'),
        cover_flow: $this.data('sw-coverflow'),
        auto_play: $this.data('sw-autoplay'),
        loop: $this.data('sw-loop'),
        centered: $this.data('sw-centered-slides'),
        pagination: $this.data('sw-pagination'),
        nav_arrows: $this.data('sw-nav-arrows')
      }

      const breakPoints = boolData.breakpoints || false
      const auto_play = boolData.auto_play !== undefined ? boolData.auto_play : false
      const speed = $this.data('sw-speed') || 1100
      const effect = $this.data('sw-effect') || 'slide'
      const showItems = $this.data('sw-show-items') || 1
      const loop = boolData.loop !== undefined ? boolData.loop : true
      const centered = boolData.centered !== undefined ? boolData.centered : true
      const spaceBetween = $this.data('sw-space-between') || (showItems > 1 ? 20 : 0)
      const scrollItems = $this.data('sw-scroll-items') || 1
      const navigationElement = $this.data('sw-navigation')
      const navigationActiveClass = $this.data('sw-navigation-active') || 'active'
      const navigationActiveSelector = boolData.active_selector !== undefined ? boolData.active_selector : false
      const paginationCss = boolData.pagination !== undefined ? boolData.pagination : '.swiper-pagination'
      const navigationCss = boolData.nav_arrows !== undefined ? boolData.nav_arrows : '.swiper-button'

      const coverflow = boolData.cover_flow ? {
        coverflowEffect: $.extend({
          stretch: 0,
          depth: 0,
          modifier: 1,
          rotate: 0,
          slideShadows: false
        }, boolData.cover_flow)
      } : {}

      const autoplay = auto_play ? {
        autoplay: {
          delay: auto_play,
          disableOnIteration: false
        },
        speed
      } : {}

      const pagination = {}

      if (paginationCss) {
        pagination.pagination = {
          el: paginationCss,
          clickable: true,
          dynamicBullets: true
        }
      }

      if (navigationCss) {
        pagination.navigation = {
          nextEl: navigationCss + '-next',
          prevEl: navigationCss + '-prev'
        }
      }

      let events = {}

      /**/ if (navigationElement) {
        events = {
          transitionEnd: function () {
            if (!navigationElement) return

            const $navigationElement = $(navigationElement)

            if (navigationActiveSelector) {
              $(navigationActiveSelector + '.' + navigationActiveClass, $navigationElement).removeClass(navigationActiveClass)
              $('.nav-item:eq(' + swiper.realIndex + ') ' + navigationActiveSelector, $navigationElement).addClass(navigationActiveClass)
            } else {
              $('.' + navigationActiveClass, $navigationElement).removeClass(navigationActiveClass)
              $('.nav-item:eq(' + swiper.realIndex + ')', $navigationElement).addClass(navigationActiveClass)
            }
          }
        }
      } /**/

      const options = $.extend({
        loop,
        slidesPerGroup: scrollItems,
        spaceBetween,
        centeredSlides: centered,
        breakpoints: breakPoints,
        slidesPerView: showItems,
        parallax: true,
        effect
      }, pagination, autoplay, coverflow)

      const swiper = new Swiper(this, options)

      for (const e in events) {
        swiper.on(e, events[e])
      }

      if (navigationElement) {
        $(navigationElement).on('click', '.nav-item', function (evt) {
          evt.preventDefault()

          const $item = $(this)
          let $activeItem = $item

          if (navigationActiveSelector) {
            $activeItem = $(navigationActiveSelector, $item)
          }

          if ($activeItem.hasClass(navigationActiveClass)) {
            return false
          }

          const index = $item.data('step') || $item.index() + 1
          swiper.slideTo(index)

          if (navigationActiveSelector) {
            $item.siblings().each(function () {
              $(navigationActiveSelector, this).removeClass(navigationActiveClass)
            })

            $activeItem.addClass(navigationActiveClass)
          } else {
            $item.siblings('.' + navigationActiveClass).removeClass(navigationActiveClass)
            $item.addClass(navigationActiveClass)
          }

          return false
        })
      }
    })
  })()
})
