import Vue from 'vue'
import $ from 'jquery'

window.constants = require('./generated/constants')
window.maps = require('./generated/maps')

window.Bus = new Vue()
window.$ = window.jQuery = $
require('bootstrap')
require('slick-carousel')
require('./configuration')

require('./components/bootstrap')

require('./landing-pages/site')

if (!Spark.hideNav) {
  require('./landing-pages/stripe-menu')
}
